import React, { Fragment, memo } from "react";
import { IMAGES } from "../../../assets/image";
import { Box, Grid } from "@mantine/core";
import classes from "./whyChoose.module.scss";
import WhyChooseCard from "./components/WhyChooseCard";
import WhyChooseHeader from "./components/WhyChooseHeader";
import { ICON_IMG } from "../../../assets/image/icons";
import { MContainer } from "../../../ui";

const WhyChoose = () => {
  return (
    <Fragment>
      <WhyChooseHeader />
      <MContainer position="relative">
        <Grid py={20} style={{ zIndex: 9999 }}>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.bottom_border} ${classes.right_border}`}
          >
            <WhyChooseCard
              icon={<img src={ICON_IMG.support_icon} alt="" width={28} />}
              count={"01"}
              title={"24/7 Withdrawal Access"}
              description={
                " Enjoy the flexibility of accessing your funds anytime with Benylo’s 24/7 withdrawal feature. Your assets are always within reach, ensuring maximum convenience and liquidity."
              }
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.bottom_border} ${classes.right_border}`}
          >
            <WhyChooseCard
              icon={<img src={ICON_IMG.secure_icon} alt="" width={28} />}
              count={"02"}
              title={"Top-Notch Security"}
              description={
                "Benylo prioritizes the security of your assets with advanced encryption protocols and stringent security measures. Your transactions and personal information are safeguarded at all times."
              }
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.bottom_border} ${classes.display_none}`}
          >
            <Box>
              <img src={IMAGES.why_choses_student} alt="" width={"100%"} />
            </Box>
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.right_border}`}
          >
            <WhyChooseCard
              icon={<img src={ICON_IMG.like_icon} alt="" width={28} />}
              count={"03"}
              title={"Seamless USD to USDT Conversion"}
              description={
                "Convert your USD to USDT effortlessly using the BNL token. Benylo’s intuitive platform ensures quick and hassle-free conversions, optimizing your trading and investment strategies."
              }
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.right_border}`}
          >
            <WhyChooseCard
              icon={<img src={ICON_IMG.like_icon} alt="" width={28} />}
              count={"04"}
              title={"User-Friendly Interface"}
              description={
                "Benylo is designed with simplicity in mind, offering an easy-to-navigate interface that caters to both beginners and experienced crypto enthusiasts. Manage your assets with ease and confidence."
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 12, md: 4 }}>
            <WhyChooseCard
              icon={<img src={ICON_IMG.global_icon} alt="" width={28} />}
              count={"05"}
              title={"Comprehensive Support"}
              description={
                "Benefit from our dedicated customer support team, available to assist you with any queries or issues. Benylo provides reliable and responsive support to ensure a smooth user experience."
              }
            />
          </Grid.Col>
        </Grid>
        <Box className={classes.bgContainer}>
          <img src={IMAGES.why_choses_bottom_bg} alt="" width={"100%"} />
        </Box>
      </MContainer>
    </Fragment>
  );
};

export default memo(WhyChoose);
