import { Box } from "@mantine/core";
import React from "react";
import FHeader from "./components/FHeader";
import FBottom from "./components/FBottom";
import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <Box className={classes.root}>
      <FHeader />
      <FBottom />
    </Box>
  );
};

export default Footer;
