const quickLink = [
  {
    label: "What is Benylo",
    link: "/",
  },
  {
    label: "BNL Token",
    link: "/",
  },
  {
    label: "Why Benylo?",
    link: "/",
  },
];
const policyLink = [
  {
    label: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    label: "Terms and Conditions",
    link: "/terms-and-conditions",
  },
  {
    label: "Whitepaper",
    link: "/whitepaper",
  },
];

const companyLink = [
  {
    label: "Who we are",
    link: "/",
  },
  {
    label: "Contact us",
    link: "/",
  },
  {
    label: "White Paper",
    link: "/",
  },
  {
    label: "API Documentation",
    link: "/",
  },
];

const supportLink = [
  {
    label: "Contact",
    link: "mailto:contact@benylo.com",
  },
];

export const footerLink = {
  quickLink: quickLink,
  policyLink: policyLink,
  companyLink: companyLink,
  supportLink: supportLink,
};
