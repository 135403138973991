import React, { memo } from "react";
import { Box, Grid } from "@mantine/core";
import { MContainer, MImgCarousel, MTypography } from "../../../ui";
import { IMAGES } from "../../../assets/image";
const SliderSection = () => {
  return (
    <MContainer>
      <Grid my={40} gutter="xl">
        {/* <Grid.Col
          span={{ base: 12, xs: 6, sm: 4, md: 3, lg: 3 }}
          style={{ color: "red" }}
        >
          <MImgCarousel
            title={"Crypto Basics"}
            data={[
              { src: IMAGES.carousel_1_1, alt: "" },
              { src: IMAGES.carousel_1_2, alt: "" },
              { src: IMAGES.carousel_1_3, alt: "" },
            ]}
          />
        </Grid.Col> */}
        <Grid.Col span={{ base: 12, xs: 6, sm: 4, md: 4, lg: 4 }}>
          <MImgCarousel
            title={"Listed BNL "}
            data={[{ src: IMAGES.carousel_2_1, alt: "" }]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, sm: 4, md: 4, lg: 4 }}>
          {/* <MTypography
            variant="description_16"
            tag="h2"
            color="white"
            align="center"
            fontWeight={500}
            marginBottom={20}
          >
            Crypto Rewards
          </MTypography>
          <Box className={classes.root}>
            <MTypography variant="subHeading_32" marginBottom={20}>
              Crypto Rewards
            </MTypography>
            <MTypography variant="description_18">
              Complete KYC and get 100 BNL tokens. Get rewarded by completing
              simple tasks.
            </MTypography>
          </Box> */}
          <MImgCarousel
            title={" Crypto Rewards"}
            data={[{ src: IMAGES.carousel_1_1, alt: "" }]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, sm: 4, md: 4, lg: 4 }}>
          {/* <MTypography
            variant="description_16"
            tag="h2"
            color="white"
            align="center"
            fontWeight={500}
            marginBottom={20}
          >
            Announcement
          </MTypography>
          <Box className={`${classes.root} ${classes.secondBg}`}>
            <MTypography variant="subHeading_32" marginBottom={20}>
              Referral & Rewards
            </MTypography>
            <MTypography variant="description_18">
              Boost your earnings with Benylo’s rewarding referral bonus
              program.
            </MTypography>
          </Box> */}
          <MImgCarousel
            title={" Crypto Rewards"}
            data={[{ src: IMAGES.carousel_1_3, alt: "" }]}
          />
        </Grid.Col>
      </Grid>
    </MContainer>
  );
};

export default memo(SliderSection);
