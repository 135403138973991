import support_icon from "./support_icon.png";
import secure_icon from "./secure_icon.png";
import like_icon from "./like_icon.png";
import cube_icon from "./cube_icon.png";
import global_icon from "./global_icon.png";

export const ICON_IMG = {
  support_icon,
  secure_icon,
  like_icon,
  cube_icon,
  global_icon,
};
