export const COLORS = {
  primary: "#1140ff",
  white: "#fff",
  gray: "#d3d3d3",
  gray_131313: "#131313",
  gray_8d98a7: "#8d98a7",
  gray_a2a2a2: " #a2a2a2",
  gray_light: "hsla(0,0%,100%,.49)",
  gray_opacity_02: "hsla(0, 0%, 100%, .2)",
  black: "#000",
  black_070507: "#070507",
  gray_a1a1a1: "#a1a1a1",
};
