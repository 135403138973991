import { createBrowserRouter } from "react-router-dom";
import Home from "../page/home/Home";
import TheLayout from "../container/TheLayout";
import PrivacyPolicy from "../page/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "../page/terms-and-conditions/TermsAndConditions";
import Whitepaper from "../page/whitepaper/Whitepaper";
import Tokenomics from "../page/tokenomics/Tokenomics";

export const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <TheLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/whitepaper",
        element: <Whitepaper />,
      },
      {
        path: "/tokenomics",
        element: <Tokenomics />,
      },
    ],
  },
]);
