import bank from "./bank.png";
import bar from "./bar.webp";
import file from "./file.webp";
import bg from "./investing-left-bg.webp";
import pig_bank from "./pig-bank.webp";
import trading from "./trading.png";
import woman_phone from "./woman-phone.png";
import party_popper from "./party-popper.webp";

export const investing = {
  bank,
  bar,
  file,
  bg,
  pig_bank,
  trading,
  woman_phone,
  party_popper,
};
