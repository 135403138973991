import React, { memo } from "react";
import classes from "./index.module.scss";
import { COLORS } from "../../assets/colors";

interface IProps {
  variant:
    | "heading_42"
    | "subHeading_32"
    | "subHeading_26"
    | "description_16"
    | "description_18"
    | "description_20"
    | "title_72";
  text?: string;
  color?: keyof typeof COLORS;
  align?:
    | "center"
    | "end"
    | "inherit"
    | "initial"
    | "justify"
    | "left"
    | "right"
    | "start";
  fontWeight?: "bold" | "bolder" | "lighter" | number;
  fontFamily?: string;
  textTransform?: "uppercase" | "capitalize" | "lowercase";
  fontSize?: string;
  marginBottom?: any;
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  children?: React.ReactNode;
  lineHeight?: string | number;
}

const MTypography = (props: IProps) => {
  const {
    variant,
    text,
    color = "white",
    align,
    fontWeight,
    fontFamily,
    textTransform,
    fontSize,
    marginBottom,
    tag = "p",
    children,
    lineHeight = "unset",
  } = props;

  const commonProps = {
    className: `${classes.default} ${classes[variant]}`,
    style: {
      color: COLORS[color],
      textAlign: align,
      fontWeight: fontWeight,
      fontFamily: fontFamily,
      textTransform: textTransform,
      fontSize: fontSize,
      marginBottom: marginBottom,
      lineHeight: lineHeight,
    },
  };

  return React.createElement(tag, commonProps, text ?? children ?? "");
};

export default memo(MTypography);
