import { Box } from "@mantine/core";
import React, { memo } from "react";
import classes from "./index.module.scss";

const PrivacyPolicy = () => {
  return (
    <div className={classes.container}>
      <Box className={classes.root}>
        <h1 className={classes.title}> Privacy Policy</h1>
        <p className={classes.description}>
          Welcome to Benylo (referred to as "we", "us", or "our"). Your privacy
          is important to us. This Privacy Policy outlines how we collect, use,
          and disclose personal information when you use our services.
        </p>
        <h1 className={classes.heading}>Information Collection and Use </h1>
        <p className={classes.description}>
          We may collect personal information, such as your name, email address,
          phone number, payment details, and KYC (Know Your Customer)
          information, when you register an account, use our services, or
          communicate with us. This information is used to provide and improve
          our services, comply with legal requirements, communicate with you,
          and personalize your experience.
        </p>
        <h1 className={classes.heading}>Data Security </h1>
        <p className={classes.description}>
          We take reasonable measures to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction. However,
          please note that no method of transmission over the internet or
          electronic storage is 100% secure
        </p>
        <h1 className={classes.heading}>Information Sharing </h1>
        <p className={classes.description}>
          We do not sell, trade, or rent your personal information to third
          parties. However, we may share your information with trusted
          third-party service providers who assist us in operating our website,
          conducting our business, or servicing you. We may also disclose your
          information in response to lawful requests by public authorities,
          including to meet national security or law enforcement requirements.
        </p>
        <h1 className={classes.heading}>Data Retention </h1>
        <p className={classes.description}>
          We will retain your personal information only for as long as necessary
          to fulfill the purposes outlined in this Privacy Policy, unless a
          longer retention period is required or permitted by law. You cannot
          delete your Benylo account, but you have the right to access, correct,
          update, or delete your personal information.
        </p>
        <h1 className={classes.heading}>Your Rights </h1>
        <p className={classes.description}>
          You have the right to access, correct, update, or delete your personal
          information. You may also opt-out of receiving marketing
          communications from us.
        </p>
        <h1 className={classes.heading}>Changes to this Privacy Policy </h1>
        <p className={classes.description}>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page.
        </p>
        <h1 className={classes.heading}>Contact Us </h1>
        <p className={classes.description}>
          If you have any questions or concerns about our Privacy Policy, or if
          you wish to exercise your rights, please contact us at
          contact@benylo.com
        </p>
      </Box>
    </div>
  );
};

export default memo(PrivacyPolicy);
