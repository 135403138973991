import { Box } from "@mantine/core";
import { Outlet } from "react-router-dom";
import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const TheLayout = () => {
  return (
    <Box>
      <Header />
      <Box>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default TheLayout;
