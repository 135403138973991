import React from "react";
import "./App.css";
import { MantineProvider } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { appRoutes } from "./router/appRouter";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";

function App() {
  return (
    <MantineProvider>
      <ModalsProvider modalProps={{ centered: true, zIndex: 999 }}>
        <Notifications position="top-right" />
        <RouterProvider router={appRoutes} />
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
