import React, { memo } from "react";
import classes from "../whyChoose.module.scss";
import { Box } from "@mantine/core";
import { MTypography } from "../../../../ui";

interface IProps {
  icon: React.ReactNode;
  count: string;
  title: string;
  description: string;
}

const WhyChooseCard = (props: IProps) => {
  const { icon, count, title, description } = props;
  return (
    <Box className={classes.card}>
      <Box className={classes.card_header}>
        <Box className={classes.card_circle}>{icon}</Box>
        <Box>
          <MTypography
            variant="subHeading_32"
            tag="p"
            marginBottom={8}
            color="gray_opacity_02"
            fontWeight={400}
          >
            {count}
          </MTypography>
        </Box>
      </Box>
      <Box my={10}>
        <MTypography
          variant="subHeading_26"
          tag="h2"
          color="white"
          fontWeight={600}
        >
          {title}
        </MTypography>
      </Box>
      <MTypography
        variant="description_16"
        tag="p"
        color="gray_a2a2a2"
        fontWeight={400}
      >
        {description}
      </MTypography>
    </Box>
  );
};

export default memo(WhyChooseCard);
