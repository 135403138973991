import React from "react";
import classes from "./MButtons.module.scss";
import { IMAGES } from "../../assets/image";

interface IProps {
  label: string;
  bg?: string;
  href?: string;
  leftContainer?: boolean;
  variant?: "primary" | "secondary";
  onClick?: () => void;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  target?: "_blank" | "_parent" | "_self" | "_top";
  margin?: number | string;
  download?: boolean;
}

const MButtons = (props: IProps) => {
  const {
    label,
    href = "/",
    leftContainer = false,
    variant = "primary",
    onClick,
    type,
    disabled,
    target = "_blank",
    margin,
    bg = "#1140ff",
    download,
  } = props;
  return (
    <div style={{ margin: margin }}>
      {variant === "primary" ? (
        <a
          download={download}
          href={href}
          className={classes.btn}
          target={target}
          onClick={(e) => {
            if (onClick) {
              e.preventDefault();
              onClick();
            }
          }}
        >
          <span className={classes.span} style={{ background: bg }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {leftContainer && (
                <img
                  src={IMAGES.play_store}
                  alt="play_store"
                  style={{ width: 30, marginRight: 6 }}
                />
              )}
              {label}
            </div>
          </span>
          <span className={classes.span} style={{ background: bg }}>
            {" "}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {leftContainer && (
                <img
                  src={IMAGES.play_store}
                  alt="play_store"
                  style={{ width: 30, marginRight: 6 }}
                />
              )}
              {label}
            </div>
          </span>
        </a>
      ) : (
        <button
          className={classes.btn}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          <span className={classes.span} style={{ background: bg }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {leftContainer && (
                <img
                  src={IMAGES.play_store}
                  alt="play_store"
                  style={{ width: 30, marginRight: 6 }}
                />
              )}
              {label}
            </div>
          </span>
          <span className={classes.span} style={{ background: bg }}>
            {" "}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {leftContainer && (
                <img
                  src={IMAGES.play_store}
                  alt="play_store"
                  style={{ width: 30, marginRight: 6 }}
                />
              )}
              {label}
            </div>
          </span>
        </button>
      )}
    </div>
  );
};

export default MButtons;
