import React from "react";
import classes from "./index.module.scss";
import { Box } from "@mantine/core";

const Tokenomics = () => {
  return (
    <div className={classes.container}>
      <Box className={classes.root}>
        <h1 className={classes.title}> Tokenomics</h1>
        <p className={classes.description}>
          The BNL token is designed to ensure a balanced distribution that
          supports the growth and sustainability of the Benylo ecosystem. The
          total supply of BNL tokens is fixed, with allocations distributed to
          various functions to promote development, user engagement, marketing,
          and liquidity.
        </p>
        <h1 className={classes.heading}>Token Distribution </h1>
        <ul>
          <li>
            <b>25% Team </b>: A quarter of the total BNL supply is allocated to
            the Benylo team. This allocation is meant to incentivize and reward
            the developers, advisors, and key contributors who are instrumental
            in the success of the project. These tokens will be vested over a
            period of time to ensure long-term commitment and alignment with the
            project's goals.
          </li>
          <li>
            <b>45% Rewards and Airdrop </b>: The largest portion of the tokens
            is reserved for community rewards and airdrops. This allocation will
            be used to incentivize user engagement, participation in mini-games,
            staking rewards, and other community-driven activities. A
            significant initial airdrop will be conducted to distribute BNL
            tokens to early adopters and participants, fostering a broad and
            engaged user base.
          </li>
          <li>
            <b>15% Marketing </b>: To ensure the growth and visibility of the
            Benylo platform, 15% of the BNL tokens are allocated for marketing
            efforts. This includes promotional campaigns, partnerships,
            influencer collaborations, and other marketing strategies aimed at
            increasing brand awareness and user adoption.
          </li>
          <li>
            <b>15% Locked Liquidity </b>: To provide stability and ensure the
            liquidity of the BNL token, 15% of the total supply is locked in
            liquidity pools. This allocation will facilitate seamless trading
            and conversion of BNL tokens on various exchanges, enhancing the
            token's accessibility and market presence.
          </li>
        </ul>
        <h1 className={classes.heading}>Conclusion </h1>
        <p className={classes.description}>
          The carefully designed tokenomics of the BNL token ensures a balanced
          approach to development, user engagement, marketing, and liquidity. By
          allocating tokens strategically, Benylo aims to build a sustainable
          and thriving ecosystem that benefits all stakeholders.
        </p>
      </Box>
    </div>
  );
};

export default Tokenomics;
