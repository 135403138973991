import { TextInput } from "@mantine/core";
import React from "react";
import classes from "./InputFelid.module.scss";

interface IProp {
  rightSection?: React.ReactNode;
  label?: string;
  placeholder: string;
  [key: string]: any;
}

const InputFelid = (props: IProp) => {
  const { rightSection, label, placeholder, ...others } = props;
  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      variant="filled"
      rightSection={rightSection}
      withAsterisk
      classNames={{
        root: classes.root,
        input: classes.input,
        wrapper: classes.wrapper,
      }}
      {...others}
    />
  );
};

export default InputFelid;
