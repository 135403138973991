import React, { Fragment } from "react";
import HeroBanner from "../../components/home-page/hero-banner/HeroBanner";
import PlayStore from "../../components/home-page/play-store/PlayStore";
import WhyChoose from "../../components/home-page/why-choose/WhyChoose";
import SliderSection from "../../components/home-page/slider/SliderSection";
import JourneyNow from "../../components/home-page/journey-now/JourneyNow";
import PieChart from "../../components/home-page/pie-chart/PieChart";
import WhatBenylo from "../../components/home-page/what-is-benylo/WhatBenylo";

const Home = () => {
  return (
    <Fragment>
      <HeroBanner />
      <SliderSection />
      <PieChart />
      <WhatBenylo />
      {/* <Investing /> */}
      <WhyChoose />
      <JourneyNow />
      <PlayStore />
    </Fragment>
  );
};

export default Home;
