import { modals } from "@mantine/modals";

export const openWebAppModal = () => {
  modals.open({
    title: "Feature Under Development",
    children: (
      <p style={{ fontWeight: 500, margin: 0 }}>
        Thank you for your interest in our web app! This feature is currently
        under development, and we are working hard to bring it to you soon.
        Please stay tuned for updates!
      </p>
    ),
  });
};
