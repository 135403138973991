import React from "react";
import { IMAGES } from "../../../assets/image";
import { MButtons, MContainer, MTypography } from "../../../ui";
import classes from "./index.module.scss";
import { Grid } from "@mantine/core";

const PieChart = () => {
  return (
    <MContainer paddingTop={30} paddingBottom={30}>
      <MTypography
        variant="heading_42"
        tag="h2"
        color="white"
        fontWeight={600}
        lineHeight={"50px"}
        marginBottom={16}
      >
        Tokenomics
      </MTypography>
      <Grid align="center" justify="space-between">
        <Grid.Col
          span={{ base: 12, sm: 6, md: 6, lg: 5 }}
          className={classes.orderFirst}
        >
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6, sm: 6, md: 6, lg: 6 }}>
              <img
                src={IMAGES.coreTeam}
                alt="coreTeam"
                className={`${classes.image} ${classes.margin}`}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6, sm: 6, md: 6, lg: 6 }}>
              <img
                src={IMAGES.marketing}
                alt="marketing"
                className={`${classes.image} ${classes.margin}`}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6, sm: 6, md: 6, lg: 6 }}>
              <img
                src={IMAGES.lockedLiquidity}
                alt="lockedLiquidity"
                className={`${classes.image} ${classes.margin}`}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6, sm: 6, md: 6, lg: 6 }}>
              <img
                src={IMAGES.rewardsAirdrops}
                alt="rewardsAirdrops"
                className={`${classes.image} ${classes.margin}`}
              />
            </Grid.Col>
          </Grid>
          {/* <Link to={"/tokenomics"} target="_top" className={classes.link}>
            Read More
          </Link> */}
          <div className={classes.buttonContainer}>
            <MButtons label=" Read More" href="/tokenomics" target="_top" />
          </div>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 7 }}>
          <div style={{ textAlign: "center" }}>
            <img
              src={IMAGES.pieChart1}
              alt="pieChart1"
              className={classes.image}
            />
          </div>
        </Grid.Col>
      </Grid>
    </MContainer>
  );
};

export default PieChart;
