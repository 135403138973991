import React from "react";
import { MContainer, MTypography } from "../../ui";
import { Box } from "@mantine/core";
import classes from "./index.module.scss";
import benyloWhitepaper from "../../assets/benylo-whitepaper.pdf";

const Whitepaper = () => {
  return (
    <Box>
      <Box className={classes.root}>
        <Box className={classes.positionContainer}>
          <Box className={classes.flexContainer}>
            <div className={classes.leftBg}></div>
            <div className={classes.rightBg}></div>
          </Box>
          <Box className={classes.position}>
            <MTypography variant="heading_42">Benylo Whitepaper</MTypography>
          </Box>
        </Box>
      </Box>
      <MContainer>
        <Box className={classes.container}>
          <MTypography variant="description_18">
            The original Benylo whitepaper is presented here for historical
            interest only. It should not be relied on for any purpose regarding
            Benylo's operations. In particular and without limiting the
            generality of the foregoing, the original Benylo whitepaper has
            never contained and does not contain legal obligations from Benylo.
          </MTypography>
          <ul>
            <li>
              <a
                href={benyloWhitepaper}
                className={classes.link}
                target="_blank"
              >
                Benylo Original Whitepaper
              </a>
            </li>
          </ul>
          <MTypography variant="description_18">
            For information about Benylo and its current operations, please
            consult the{" "}
            <a href="/terms-and-conditions" className={classes.link}>
              Benylo terms of service.
            </a>
          </MTypography>
        </Box>
      </MContainer>
    </Box>
  );
};

export default Whitepaper;
