import { Box, Grid } from "@mantine/core";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../assets/image";
import NewsLetter from "./NewsLetter";

const FHeader = () => {
  return (
    <Grid>
      <Grid.Col span={{ lg: 4 }}>
        <Box>
          <Link to="/">
            <img src={IMAGES.logo} alt="" width={150} />
          </Link>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ lg: 8 }}>
        <NewsLetter />
      </Grid.Col>
    </Grid>
  );
};

export default memo(FHeader);
