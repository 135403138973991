import React, { useEffect, useRef, useState } from "react";
import classes from "./HeroBanner.module.scss";
import { Box, BackgroundImage } from "@mantine/core";
import { IMAGES } from "../../../assets/image";
import { MButtons, MTypography } from "../../../ui";
import { COLORS } from "../../../assets";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import "@mantine/carousel/styles.css";
import { openPlayStorModal } from "../../../modals/playStore.modal";
import benyloApk from "../../../assets/Benylo-40.apk";
import { client } from "../../../service/axios.sevice";
import { openWebAppModal } from "../../../modals/webApp.modal";

const HeroBanner = () => {
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  const [apkUrl, setApkUrl] = useState("");
  const getSetting = async () => {
    try {
      const res = await client({ url: "/web/fetch-settings", method: "GET" });
      if (res.data?.status === "success") {
        setApkUrl(res?.data.data.applicationUrl ?? "");
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <BackgroundImage src={IMAGES.circle_gradient} className={classes.bgImage}>
      <Box className={classes.root}>
        <Box className={classes.widthContainer}>
          <MTypography
            variant="description_20"
            tag="h3"
            fontWeight={500}
            marginBottom={8}
          >
            Secure, Simple, Seamless:
          </MTypography>
          <MTypography
            variant="title_72"
            tag="h3"
            fontWeight={500}
            marginBottom={8}
            lineHeight={"82px"}
          >
            <span style={{ color: COLORS.primary }}>USD </span> to
            <span style={{ color: COLORS.primary }}> USDT </span>with
            <span style={{ color: COLORS.primary }}> BNL </span>
          </MTypography>
          <MTypography
            variant="description_16"
            tag="p"
            fontWeight={400}
            marginBottom={16}
          >
            Experience effortless currency conversion and secure trading,
            anytime, anywhere with Benylo
          </MTypography>
          <div className={classes.row}>
            <MButtons
              label="Play Store"
              // href="https://play.google.com/store/apps/details?id=com.benylo&pli=1"
              leftContainer={true}
              onClick={openPlayStorModal}
              variant="secondary"
              margin={"6px 0px "}
            />
            <MButtons
              label="Download Latest APK"
              href={apkUrl}
              download={true}
              leftContainer={false}
              margin={"6px 6px "}
            />
            <MButtons
              label="launch Web App"
              // href={benyloApk}
              leftContainer={false}
              bg="#08af08"
              onClick={openWebAppModal}
              variant="secondary"
              margin={"6px 0px "}
            />
          </div>
        </Box>
        <Box className={classes.rightWidthContainer}>
          <Carousel
            withIndicators={false}
            withControls={false}
            // height={200}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            loop
          >
            <Carousel.Slide style={{ borderRadius: "10px" }}>
              <img src={IMAGES.screen1} alt="" className={classes.rightImage} />
            </Carousel.Slide>
            <Carousel.Slide style={{ borderRadius: "10px" }}>
              <img src={IMAGES.screen2} alt="" className={classes.rightImage} />
            </Carousel.Slide>
            <Carousel.Slide style={{ borderRadius: "10px" }}>
              <img src={IMAGES.screen5} alt="" className={classes.rightImage} />
            </Carousel.Slide>
          </Carousel>
        </Box>
      </Box>
    </BackgroundImage>
  );
};

export default HeroBanner;
