import { modals } from "@mantine/modals";

export const openPlayStorModal = () => {
  modals.open({
    title: "Feature Under Development",
    children: (
      <p style={{ fontWeight: 500, margin: 0 }}>
        Application is under development. It will be updated soon.
      </p>
    ),
  });
};
