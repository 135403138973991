import React, { memo } from "react";
import classes from "./Container.module.scss";
interface IProps {
  children: React.ReactNode;
  backgroundImg?: string;
  position?: "relative" | "fixed" | "static" | "absolute" | "sticky";
  paddingTop?: any;
  paddingBottom?: any;
}

const MContainer = (props: IProps) => {
  const { children, backgroundImg, position, paddingTop, paddingBottom } =
    props;
  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: backgroundImg,
        position: position,
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
      }}
    >
      {children}
    </div>
  );
};

export default memo(MContainer);
