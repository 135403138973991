import React from "react";
import { MContainer, MTypography } from "../../../ui";
import { Box } from "@mantine/core";

const WhatBenylo = () => {
  return (
    <MContainer>
      <Box pb={50} id="what-is-benylo">
        <MTypography
          variant="heading_42"
          tag="h2"
          color="white"
          fontWeight={600}
          lineHeight={"50px"}
          marginBottom={16}
        >
          What is
          <span style={{ fontWeight: 100 }}> Benylo ?</span>
        </MTypography>
        <MTypography variant="description_16" marginBottom={16}>
          Benylo is a cutting-edge cryptocurrency platform designed to provide
          seamless financial solutions for users. Our application offers a range
          of services including the ability to deposit, withdraw, and convert
          USD to USDT effortlessly. With Benylo, users can securely manage their
          digital assets, ensuring smooth and efficient transactions across
          multiple crypto exchanges. Our goal is to simplify the complexities of
          digital finance, making it accessible and user-friendly for everyone.
        </MTypography>
      </Box>
      <Box id="bnl-token">
        <MTypography
          variant="heading_42"
          tag="h2"
          color="white"
          fontWeight={600}
          lineHeight={"50px"}
          marginBottom={16}
        >
          BNL Token
        </MTypography>
        <MTypography variant="description_16" marginBottom={16}>
          The BNL Token is a specialized cryptocurrency that serves as a crucial
          bridge between USD and USDT on the Benylo platform. By utilizing BNL
          tokens, users can seamlessly convert their USD holdings into USDT,
          enabling quick and efficient transactions across various crypto
          exchanges. The BNL token not only facilitates smooth conversions but
          also enhances the overall liquidity and functionality of the Benylo
          ecosystem.​​​​​​​
        </MTypography>
      </Box>
    </MContainer>
  );
};

export default WhatBenylo;
