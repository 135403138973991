import { Box } from "@mantine/core";
import React, { memo } from "react";
import classes from "./index.module.scss";
const TermsAndConditions = () => {
  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <h1 className={classes.title}>Terms and Conditions</h1>
        <p className={classes.description}>
          Welcome to Benylo, your trusted platform for seamless cryptocurrency
          transactions and financial empowerment. Benylo serves as a bridge for
          users to leverage the benefits of digital assets, including the BNL
          token, and facilitates the smooth conversion of USD to USDT without
          any hassle.
        </p>
        <p className={classes.description}>
          At Benylo, we are committed to providing users with a secure and
          transparent environment to explore the world of cryptocurrencies and
          unlock new opportunities for financial growth. By accessing or using
          the Benylo platform, you agree to abide by the following terms and
          conditions:
        </p>
        <ul>
          <li>
            <b>User Conduct </b>:Users are responsible for their conduct while
            using the Benylo platform. Any misuse, fraudulent activities, or
            violations of our policies will result in immediate termination of
            the user's account.
          </li>
          <li>
            <b>Deposits and Withdrawals </b>: Users are solely responsible for
            ensuring the accuracy and security of their deposits and
            withdrawals. Benylo is not liable for any loss or damage resulting
            from incorrect or unauthorized transactions.
          </li>
          <li>
            <b>Risk Disclosure</b>:Cryptocurrency investments carry inherent
            risks, including but not limited to market volatility and loss of
            funds. Benylo does not guarantee any fixed return, and investment in
            the crypto industry is highly risky. Users are advised to manage
            their risk accordingly, conduct thorough research, and exercise
            caution when investing in liquidity pools or trading
            cryptocurrencies.
          </li>
          <li>
            <b>Account Suspension</b>: Benylo reserves the right to suspend or
            terminate user accounts in cases of suspected fraud, violation of
            terms, or disruptive behavior. Users will be notified of any such
            actions via email or the platform's messaging system.
          </li>
          <li>
            <b>Verification and Security</b>: Users must complete the KYC (Know
            Your Customer) verification process to access certain features of
            the platform. Failure to provide accurate information or comply with
            security measures may result in account suspension or termination.
          </li>
          <li>
            <b>Intellectual Property</b>: All content and materials provided on
            the Benylo platform are the intellectual property of Benylo and may
            not be reproduced or distributed without prior permission.
          </li>
          <li>
            <b>Customer Support</b>: Benylo provides customer support services
            to assist users with inquiries and issues. Users are expected to
            communicate respectfully and refrain from abusive or harassing
            behavior towards customer support representatives.
          </li>
          <li>
            <b>Disclaimer of Liability</b>: Benylo shall not be liable for any
            direct, indirect, incidental, or consequential damages arising from
            the use of the platform or reliance on information provided therein.
          </li>
          <li>
            <b>Changes to Terms</b>: Benylo reserves the right to modify or
            update these terms and conditions at any time without prior notice.
            Users are encouraged to review the terms periodically for changes.
          </li>
          <li>
            <b>Prohibited Activities</b>: Users are prohibited from engaging in
            any illegal or fraudulent activities on the Benylo platform,
            including but not limited to money laundering, terrorist financing,
            or any other criminal activities. Any suspicious activities will be
            reported to the relevant authorities.
          </li>
          <li>
            <b>Third-Party Services</b>: Benylo may integrate third-party
            services or links to external websites for user convenience.
            However, Benylo is not responsible for the content, security, or
            privacy practices of third-party websites or services. Users should
            review the terms and conditions of third-party services before
            accessing or using them.
          </li>
          <li>
            <b>Indemnification</b>: 10. Users agree to indemnify and hold
            harmless Benylo, its affiliates, directors, officers, employees, and
            agents from any claims, losses, damages, liabilities, or expenses
            arising out of or related to their use of the platform, violation of
            these terms and conditions, or infringement of any rights of third
            parties.
          </li>
          <li>
            <b>Force Majeure</b>: Benylo shall not be liable for any failure or
            delay in performing its obligations under these terms and conditions
            due to circumstances beyond its reasonable control, including but
            not limited to natural disasters, acts of war, government
            regulations, or technological issues.
          </li>
          <li>
            <b>Governing Law and Jurisdiction</b>: These terms and conditions
            shall be governed by and construed in accordance with the laws of
            jurisdiction. Any disputes arising out of or related to these terms
            and conditions shall be subject to the exclusive jurisdiction of the
            courts in Delhi.
          </li>
          <li>
            <b>Severability</b>: If any provision of these terms and conditions
            is found to be invalid or unenforceable, the remaining provisions
            shall remain in full force and effect. The invalid or unenforceable
            provision shall be replaced by a valid and enforceable provision
            that most closely matches the intent of the original provision.
          </li>
          <li>
            <b>Language</b>: These terms and conditions may be provided in
            multiple languages for user convenience. In the event of any
            discrepancy or inconsistency between different language versions,
            the English version shall prevail.
          </li>
        </ul>
        <p className={classes.description}>
          These additional terms and conditions can help further clarify the
          rights and responsibilities of users and Benylo, as well as provide
          legal protection for both parties. Let me know if you'd like to
          include any of these points or if you have other ideas in mind!
        </p>
        <p className={classes.description}>
          By using the Benylo platform, you acknowledge that you have read,
          understood, and agreed to these terms and conditions. If you do not
          agree with any part of these terms, please refrain from using the
          platform.
        </p>
        <p className={classes.description}>
          For inquiries or assistance, please contact our customer support team
          at contact@benylo.com
        </p>
      </Box>
    </Box>
  );
};

export default memo(TermsAndConditions);
