import React, { memo } from "react";
import { Box, Grid } from "@mantine/core";
import { MContainer, MTypography } from "../../../../ui";
import { IMAGES } from "../../../../assets/image";
import { COLORS } from "../../../../assets";
import classes from "../whyChoose.module.scss";

const WhyChooseHeader = () => {
  return (
    <MContainer position="relative">
      <Grid id="why-benylo">
        <Grid.Col span={{ base: 12, sm: 12, md: 12, lg: 12 }}>
          <MTypography
            variant="heading_42"
            tag="h2"
            color="white"
            fontWeight={600}
            lineHeight={"50px"}
          >
            Why
            <span style={{ fontWeight: 100 }}> Benylo ?</span>
          </MTypography>
        </Grid.Col>
        {/* <Grid.Col span={{ base: 12, sm: 12, md: 12, lg: 8 }}>
          <Box style={{ padding: "16px 0px" }}>
            <Grid>
              <Grid.Col span={{ base: 12, sm: 3, md: 3 }}>
                <MTypography
                  variant="subHeading_32"
                  tag="h2"
                  color="white"
                  fontWeight={700}
                  lineHeight={"50px"}
                >
                  2.12 M<span style={{ color: COLORS.primary }}>+</span>
                </MTypography>
                <MTypography
                  variant="description_16"
                  tag="h3"
                  color="gray_a2a2a2"
                  fontWeight={400}
                  lineHeight={1.2}
                >
                  Customers served
                </MTypography>
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 4, md: 4 }}>
                <MTypography
                  variant="subHeading_32"
                  tag="h2"
                  color="white"
                  fontWeight={700}
                  lineHeight={"50px"}
                >
                  Since <span style={{ color: COLORS.primary }}>2014</span>
                </MTypography>
                <MTypography
                  variant="description_16"
                  tag="h3"
                  color="gray_a2a2a2"
                  fontWeight={400}
                  lineHeight={1.2}
                >
                  CServing in Blockchain
                </MTypography>
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 3, md: 3 }}>
                {" "}
                <MTypography
                  variant="subHeading_32"
                  tag="h2"
                  color="white"
                  fontWeight={700}
                  lineHeight={"50px"}
                >
                  41.24 M<span style={{ color: COLORS.primary }}>+</span>
                </MTypography>
                <MTypography
                  variant="description_16"
                  tag="h3"
                  color="gray_a2a2a2"
                  fontWeight={400}
                  lineHeight={1.2}
                >
                  Orders Processed
                </MTypography>
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 2, md: 2 }}>
                {" "}
                <MTypography
                  variant="subHeading_32"
                  tag="h2"
                  color="white"
                  fontWeight={700}
                  lineHeight={"50px"}
                >
                  160<span style={{ color: COLORS.primary }}>+</span>
                </MTypography>
                <MTypography
                  variant="description_16"
                  tag="h3"
                  color="gray_a2a2a2"
                  fontWeight={400}
                  lineHeight={1.2}
                >
                  Countries
                </MTypography>
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col> */}
      </Grid>
      {/* <Box className={classes.headerTopImg}>
        <img
          src={IMAGES.why_choses_top_bg}
          alt=""
          width={"100%"}
          height={"100%"}
        />
      </Box> */}
    </MContainer>
  );
};

export default memo(WhyChooseHeader);
