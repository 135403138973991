import React, { memo, useState } from "react";

import classes from "./NewsLetter.module.scss";
import { MTypography, MInputFelid, MButtons } from "../../../ui";
import { Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { client } from "../../../service/axios.sevice";
import { notifications } from "@mantine/notifications";

const initialValues = {
  recipient: "",
};

const NewsLetter = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: initialValues,

    validate: {
      recipient: (value) =>
        value.length < 2 ? "Recipient's username is Required" : null,
    },
  });

  const handleSubmit = async (data: typeof initialValues) => {
    try {
      setLoading(true);
      const res = await client({ url: "/web/contact", method: "POST", data });
      if (res.data?.status === "success") {
        notifications.show({
          title: res.data.title,
          message: res.data.message,
          color: "green",
        });

        form.reset();
      }
    } catch (error: any) {
      notifications.show({
        title: error.response.data.title,
        message: error.response.data.message,
        color: "red",
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <MTypography
        variant="subHeading_26"
        tag="h3"
        fontWeight={600}
        marginBottom={8}
      >
        Get in Touch with Us!
      </MTypography>
      <MTypography
        variant="description_16"
        tag="h5"
        fontWeight={300}
        marginBottom={8}
        color="gray_light"
      >
        Join the BENYLO Community to get the latest news and releases...
      </MTypography>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.flexContainer}>
          <Box className={classes.leftWidthContainer}>
            <MInputFelid
              placeholder={`Recipient's username`}
              {...form.getInputProps("recipient")}
            />
          </Box>

          <MButtons
            label={loading ? "Loading..." : "Submit"}
            disabled={loading}
            variant="secondary"
            type={"submit"}
          />
        </Box>
      </form>
    </div>
  );
};

export default memo(NewsLetter);
