import { Carousel } from "@mantine/carousel";
import React, { memo, useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import "@mantine/carousel/styles.css";
import MTypography from "../typography/MTypography";

interface IProps {
  title: string;
  data: {
    src: string;
    alt?: string;
  }[];
}

const ImgCarousel = (props: IProps) => {
  const { title, data } = props;
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  return (
    <>
      <MTypography
        variant="description_16"
        tag="h2"
        color="white"
        align="center"
        fontWeight={500}
        marginBottom={20}
      >
        {title}
      </MTypography>
      <Carousel
        withIndicators={false}
        withControls={false}
        // height={200}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        loop
      >
        {data.map((item) => (
          <Carousel.Slide key={item.src} style={{ borderRadius: "10px" }}>
            <img
              src={item.src}
              style={{ borderRadius: 10 }}
              alt={item.alt}
              width={"100%"}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </>
  );
};

export default memo(ImgCarousel);
